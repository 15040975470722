<template>
  <div class="center_warp">
    <div class="center_content">
      <div class="application">
        <p class="coment_title"><span>个人应用</span> <span @click="manage" class="manage">{{this.circle ? '取消' : '管理'}}</span> </p>
        <div class="application_list">
          <a-row
            class="list_row"
            :gutter="10"
          >
            <a-col
              span="3"
              v-for="app in myApp"
              :key="app.id"
              @click="appJump(app)"
            >
              <div class="list_li">
                <div class="li_avatar">
                  <img
                    :src="staticDomainURL + app.appimage"
                  >
                </div>
                <div class="li_name">{{app.appname}}</div>
                <div v-if="circle" @click="deleteApp(app)" class="li_delete">
                  <a-icon type="close-circle"></a-icon>
                </div>
              </div>
            </a-col>
            <a-col span="3">
              <div class="list_li">
                <div
                  class="li_add"
                  @click="addApp"
                >
                  <a-icon
                    class="icon"
                    type="plus"
                  />
                </div>
                <div class="li_name">添加新应用</div>
              </div>
            </a-col>
          </a-row>

        </div>
      </div>
      <div class="second_warp">
        <div>
          <div class="my_agent">
            <div class="agent_head">
              <a-row :gutter="24">
                <a-col :md="3" :sm="4">
                  <span class="agent_title">我的待办</span>
                </a-col>
                <a-col :md="6" :sm="8">
                  <a-input
                    class="search_input"
                    v-model="queryParam.workflowName"
                    placeholder="流程名称查询"
                  />
                </a-col>
                <a-col :md="6" :sm="8">
                  <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons" >
                    <a-button class="search_btn search_query" type="primary" @click="searchQuery" >查询</a-button>
                    <a-button class="search_btn" style="margin-left: 8px" @click="searchReset" >重置</a-button>
                  </span>
                </a-col>
              </a-row>
            </div>
            <!-- table区域 -->
            <div class="agent_table">
              <a-table 
                  ref="table"
                  rowKey="id"
                  size="middle"
                  :columns="columns"
                  :customRow="rowClick" 
                  :data-source="dataSource"
                  :pagination="ipagination"
                  :loading="loading"
                  @change="handleTableChange"
                  >
                  <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
              </a-table>
            </div>
          </div>
          <div class="staff_warp">
            <div class="staff_head">
              员工360
            </div>
            <div class="staff_server">
              <div class="img_warp">
                <img src="~@/assets/img/expect.png" alt="">
              </div>
              <span>暂未开通服务，敬请期待</span>
            </div>
          </div>
        </div>
        <div class="schedule_warp">
          <div class="schedule_title">我的日程</div>
          <div>
            <a-calendar  :fullscreen="false" mode="month" :header-render="headerRender" @panelChange="onPanelChange" @select="onSelect">
              <div slot="dateCellRender" slot-scope="value" class="events">
                <!-- <span class="circle"></span> -->
                <span v-for="(item,index) in getListData(value)" :key="index" class="events">
                  <span class="circle"></span>
                </span>
              </div>
            </a-calendar>
          </div>
          <div class="add_schedule">
            <a-button class="addBtn" @click="addRender">新建日程</a-button>
          </div>
          <div class="render_list">
            <div class="list_title">
              {{selectTime}}
            </div>
            <div class="list_warp">
              <p class="list_li" v-for="(item, index) in  selectList" :key="index" @click="renderInfo(item)">
                <span>{{item.startTime.slice(11,16)}}</span>
                <span class="vertical"></span>
                <span class="li_title" :title="item.title">{{item.title}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-app ref="addAppFrom" @ok="updateApp" />
    <a-modal
        title="日程添加"
        :width="600"
        :visible="visible"
        dialogClass="addCalen"
        @ok="handleOk"
        @cancel="handleCancel"
        okText="添加"
      >
        <template slot="footer">
          <a-button key="back" @click="handleCancel">关闭</a-button>
          <a-button v-if="isEdit" class="deleteBtn" @click="handleDelete">删除</a-button>
          <a-button class="okBtn" key="confirm" @click="handleOk">{{!isEdit ? '添加' : '修改'}}</a-button>
          
        </template>
        <a-form>
          <a-row :gutter="10"> 
              <a-col :sm="24" :md="12" :xl="12">
                <a-form-item 
                  label="标题" 
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                  required>
                  <a-input v-model="addEvent.title" placeholder="请输入标题" style="width:100%;"></a-input>
                </a-form-item>
              </a-col>
              <a-col :sm="24" :md="12" :xl="12">
                <a-form-item 
                  label="开始时间" 
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                  required>
                  <j-date
                      v-model="addEvent.startTime"
                      :showTime="{defaultValue:moment('00:00:00', 'HH:mm:ss')}"
                      date-format="YYYY-MM-DD HH:mm:ss"
                      placeholder="请选择开始日期"
                      style="width:100%;"
                  ></j-date>
                </a-form-item>
              </a-col>
              <a-col :sm="24" :md="12" :xl="12">
                <a-form-item 
                  label="结束时间" 
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                  required>
                  <j-date
                      v-model="addEvent.endTime"
                      :showTime="{defaultValue:moment('00:00:00', 'HH:mm:ss')}"
                      date-format="YYYY-MM-DD HH:mm:ss"
                      placeholder="请选择结束日期"
                      style="width:100%;"
                  ></j-date>
                </a-form-item>
              </a-col>
              <a-col :sm="24" :md="12" :xl="12">
                <a-form-item 
                  label="提醒时间" 
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol">
                   <a-select default-value="15" placeholder="请选择提醒时间" v-model="addEvent.reminderTime" style="width:100%;">
                    <a-select-option value="15">
                      15分钟
                    </a-select-option>
                    <a-select-option value="30">
                      30分钟
                    </a-select-option>
                    <a-select-option value="60">
                      1小时
                    </a-select-option>
                    <a-select-option value="120">
                      2小时
                    </a-select-option>
                    <a-select-option value="240">
                      4小时
                    </a-select-option>
                    <a-select-option value="1440">
                      1天
                    </a-select-option>
                   </a-select>
                  <!-- <j-date
                      v-model="addEvent.reminderTime"
                      :showTime="true"
                      date-format="YYYY-MM-DD HH:mm"
                      placeholder="请选择提醒时间"
                      style="width:100%;"
                  ></j-date> -->
                </a-form-item>
              </a-col>
              <a-col :sm="24" :md="24" :xl="24">
                <a-form-item 
                  label="备注" 
                  :label-col="{xs: { span: 24 },sm: { span: 4 }}"
                  :wrapper-col="{xs: { span: 24 },sm: { span: 20 }}">
                  <a-textarea v-model="addEvent.description" :auto-size="{ minRows: 3, maxRows: 5 }" placeholder="请输入备注"></a-textarea>
                </a-form-item>
              </a-col>
          </a-row>
          
        </a-form>
      </a-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import { filterObj } from '@/utils/util';
import { USER_INFO } from '@/store/mutation-types'
import { getAction, postAction, putAction, deleteAction} from '@/api/manage'
import addApp from './modules/addApp'
import { JeecgListMixin } from '@/views/mixins/mixin'
import moment from 'moment'
var time = null;
const _evebtRequired = ['title','startTime','endTime']
const TumpLink = {H3: '/mesnac/DD/getH3URLDD', IFLOW: '/mesnac/DD/getIflowURL', SFA: '/mesnac/DD/getSFAURL', SIIP:'/mesnac/DD/getSIIPUrl', YS:'/mesnac/DD/getYSUrl',  CW:'/mesnac/DD/getCWDSJ' }


export default {
  mixins:[JeecgListMixin],
  components: {
    addApp
  },
  data() {
    return {
      TumpLink,
      visible: false,
      isEdit: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      addEvent: {},
      selectTime: moment().format("YYYY 年 MM 月 DD 日"),
      myApp: [],
      circle: false,
      workNo:Vue.ls.get(USER_INFO).workNo,
      userName: Vue.ls.get(USER_INFO).username,
      queryParam: { username: Vue.ls.get(USER_INFO).username },
      extraParam: { username: Vue.ls.get(USER_INFO).username },
      userInfo: Vue.ls.get(USER_INFO),
      staticDomainURL: window._CONFIG['staticDomainURL'],
      columns:[
        {
          width:'130px',
          title: '任务名称',
          align: "center",
          dataIndex: 'activityName',
          ellipsis: true,
        },
        {
          ellipsis: true,
          title: '流程名称',
          align: "center",
          dataIndex: 'workflowName'
        },
      ],
      testList: [],
      selectList: [],
      url: {
        list:'/mesnac/LCZX/selectDB'
      }
    }
  },
  created() {
    this.getApplication()
    this.getRender()
  },
  methods: {
    updateApp() {
      this.getApplication()
    },
    // 获取我的应用
    getApplication() {
      getAction('/mesnac/zmdgrzx/zmdMyapps/getUserApp', { userId: this.userInfo.id }).then(res => {
        if (res.success) {
          this.myApp = res.result
        }
      })
    },
    // 
    getListData(value) {
      let listData;
      //遍历数组
      this.testList.forEach(e => {
      //让数组的date与遍历到日历的那天的日期值相匹配
        if (e.date === value.format("YYYY-MM-DD")) {
          listData = e.listData;
        }
      });
      return listData || [];
      
    },
    getQueryParams() {
      //获取查询条件
      let sqp = {}
      if(this.superQueryParams){
        sqp['superQueryParams']=encodeURI(this.superQueryParams)
        sqp['superQueryMatchType'] = this.superQueryMatchType
      }
      var param = Object.assign(sqp, this.queryParam, this.isorter ,this.filters);
      param.field = this.getQueryField();
      param.page = this.ipagination.current;
      param.size = this.ipagination.pageSize;
      return filterObj(param);
    },
    // 添加应用
    addApp() {
      this.$refs.addAppFrom.add()
    },
    // app 跳转
    appJump(app) {
      if(!this.circle) {
        const that = this
        clearTimeout(time);
        time = setTimeout(function(){
          if(app.id == '1352506388391149569') {
            that.$router.push({path:'/meeting'})
          } else {
          console.log('app: ', app);
            if(app.appdescribe == 'tj') {
              let url = TumpLink[app.apppath]
              if(url == '/mesnac/DD/getCWDSJ') {
            getAction(url, {employeeNo: that.workNo}).then(res => {
              if(res.success) {
                let name = res.result
                let location = `http://172.16.77.194:8080/bi/SSO.jsp?code=${name}`
                var tempwindow=window.open('_blank');
                tempwindow.location=location;
              } else {
                that.$message.warn('您没有权限登录!')
              }
            })
          } else {
            getAction(url, {username: that.userName }).then(res => {
              if(res.success) {
                var tempwindow=window.open('_blank');
                tempwindow.location=res.result;
              } else {
                that.$message.warn(res.message)
              }
            })
          }
            } else {
              var tempwindow=window.open('_blank');
              tempwindow.location=app.apppath;
            }
          }
         
        }, 300);
      }
    },
    manage() {
      this.circle = !this.circle
    },
    rowClick(record) {
        return {
            on:{
                click:() => {
                    var tempwindow=window.open('_blank');
                    tempwindow.location=record.url;
                }
            }
        }
    },
    // 删除app
    deleteApp(app) {
      clearTimeout(time)
      console.log('app: ', app);
      if(this.circle) {
        this.$confirm({
          title:'确定要删除吗？',
          icon:'delete',
          className:'deleteModl',
          onOk:() => {
            console.log(app)
            getAction('mesnac/zmdgrzx/zmdMyapps/deleteByUserIdAndAppId', {appId: app.id, userId:this.userInfo.id }).then(res => {
              if(res.success) {
                this.$message.success(res.message)
                this.getApplication()
              } else {
                this.$message.warn(res.message)
              }
            })
          }
        })
      }
      
    },
    onPanelChange(value, mode) {
      // eslint-disable-next-line no-console
      console.log(value, mode);
    },
    headerRender({ value, onChange }) {
      const current = value.clone();
      const localeData = value.localeData();
      const nowMoth = localeData.monthsShort(current)
      const month = value.month();
      const year = value.year();
      return (
        <div class="calendarHeader" style={{ padding: '10px' }}>
              <a-button
                class="calendarBtn prevBtn"
                onClick={
                () => {
                  if (month > 1) {
                  const newValue = value.clone();
                  let nexMonth = month-2
                  current.month(nexMonth);
                  let a = localeData.monthsShort(current)
                  newValue.month(parseInt(a, 10));
                  onChange(newValue);
                  } else {
                    const year = value.year() - 1;
                    const now = value.clone().year(year);
                    const newValue = now
                    let nexMonth = month-2
                    current.month(nexMonth);
                    let a = localeData.monthsShort(current)
                    newValue.month(parseInt(a, 10));
                    onChange(newValue);
                  }
                }} 
              > <a-icon type="left"/> </a-button>
              <span>{String(year)+'年'+ nowMoth}</span>
              <a-button class="calendarBtn nextBtn" onClick={
                () => {
                  const newValue = value.clone();
                  current.month(month);
                  let a = localeData.monthsShort(current)
                  console.log('a: ', a);
                  newValue.month(parseInt(a, 10));
                  onChange(newValue);
              }} > <a-icon type="right"/> </a-button>
        </div>
      );
    },
    // 日程选择事件
    onSelect(date) {
      this.selectList = []
      this.selectTime = date.format("YYYY 年 MM 月 DD 日")
      let time = date.format("YYYY-MM-DD")
      console.log('this.testList: ', this.testList);
      this.testList.forEach(item => {
        if(item.date == time) {
          this.selectList.push(item.listData[0])
        } else {
          this.selectList.push()
        }
      })
    },
    // 获取日程
    getRender() {
      this.selectList = []
      this.testList = []
      let formData = {
        username: this.userName,
        userid: this.userInfo.id,
        pageSize: 10000,
      }
      getAction('/mesnac/zmdgrzx/zmdSchedulePlanning/list', formData).then(res => {
        if(res.success) {
          let data = res.result.records
          data.forEach(item => {
            if(item.startTime) {
              let startTime = item.startTime.slice(0,10)
              this.testList.push({date: startTime, listData:[item]})
            }
          })
          let currentTime = moment().format("YYYY-MM-DD")
          this.testList.forEach(item => {
            if(item.date == currentTime) {
              this.selectList.push(item.listData[0])
            }
          })
        }
      })
    },
    // 新建日程
    addRender() {
      this.visible = true
    },
    handleCancel() {
      this.visible = false
      this.isEdit = false
      this.addEvent= {}
    },
    handleOk() {
      let flag1 = _evebtRequired.some((e) => {
        if(typeof this.addEvent[e] === 'string') this.addEvent[e] = this.addEvent[e].trim()
        return this.addEvent[e] == null || this.addEvent[e] === ''
      })
      if (flag1) {
        this.$message.warning('请填写日程必填信息')
        return
      }
      let formData = {
        username: this.userName,
        userid: this.userInfo.id,
        ...this.addEvent
      }
      console.log('formData', formData)
      if(this.isEdit) {
        putAction('/mesnac/zmdgrzx/zmdSchedulePlanning/edit', formData).then (res => {
          if(res.success) {
            this.$message.success(res.message)
            this.handleCancel()
          } else {
            this.$message.warn(res.message)
          }
        }).finally(() => {
          this.getRender()
        })
      } else {
        postAction('/mesnac/zmdgrzx/zmdSchedulePlanning/add', formData).then (res => {
          if(res.success) {
            this.$message.success(res.message)
            this.handleCancel()
          } else {
            this.$message.warn(res.message)
          }
        }).finally(() => {
          this.handleCancel()
          this.getRender()
        })
      }
    },
    handleDelete() {
      console.log('addEvent', this.addEvent)
      deleteAction('/mesnac/zmdgrzx/zmdSchedulePlanning/delete',{ id :this.addEvent.id} ).then(res => {
        if(res.success) {
          this.$message.success(res.message)
          this.handleCancel()
        }else {
          this.$message.warning(res.message)
        }
      }).finally(()=> {
        this.getRender()
      })
    },
    // 日程详情
    renderInfo(data) {
      this.isEdit = true
      this.visible = true
      this.addEvent = data
    },
    //重置
    searchReset() {
      this.queryParam.workflowName = ''
      this.loadData(1)
    }
    
  }
}
</script>
<style lang="less" scoped>
.center_warp {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
  // background: url('~@/assets/img/home_bg.png') no-repeat top center / cover;
  background: #f6f6f6;
  .center_content {
    width: 1500px;
    margin: 0 auto;
    position: relative;
    .application {
      background: #fff;
      padding: 20px;
      .coment_title {
        display: flex;
        justify-content: space-between;
        font-size: 22px;
        color: #101010;
        .manage{
          font-size: 16px;
          cursor: pointer;
          display: inline-block;
          width: 60px;
          height: 30px;
          color: #bc0039;
          border: 1px solid #bc0039;
          border-radius: 15px;
          text-align: center;
          line-height: 30px;
        }
      }
      .application_list {
        padding: 0px 10px;
        .list_row {
          width: 100%;
        }
        .list_li {
          cursor: pointer;
          text-align: center;
          margin-bottom: 10px;
          position: relative;
          .li_add {
            cursor: pointer;
            margin: 0 auto;
            width: 70px;
            height: 70px;
            border: 1px solid rgba(187, 187, 187, 100);
            text-align: center;
            line-height: 70px;
            .icon {
              font-size: 24px;
            }
          }
          .li_avatar {
            margin: 0 auto;
            height: 70px;
            img {
              width: 70px;
              height: 70px;
            }
          }
          .li_name {
            margin-top: 8px;
            font-size: 14px;
            color: #101010;
            text-overflow:ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .li_delete{
            position: absolute;
            top: -10px;
            right: -10px;
            color: red;
            z-index: 100;
          }
        }
      }
    }
    .second_warp {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      .my_agent {
        width: 1030px;
        background: #fff;
        padding: 10px 30px;
        .agent_head {
          margin-bottom: 10px;
          .agent_title {
            font-size: 22px;
            color: #101010;
            line-height: 40px;
          }
          /deep/.ant-form-item-control {
            .ant-calendar-picker {
              .ant-calendar-picker-input {
                height: 40px;
                border: 1px solid rgba(188, 0, 57, 100);
                border-radius: 10px;
                color: rgba(188, 0, 57, 100);
                font-size: 14px;
                &::-webkit-input-placeholder,
                textarea::-webkit-input-placeholder {
                  color: rgba(188, 0, 57, 100);
                }
              }
              .anticon {
                color: #bc0039;
              }
            }
          }
          .search_input {
            height: 40px;
            border: 1px solid rgba(188, 0, 57, 100);
            border-radius: 10px;
            color: rgba(188, 0, 57, 100);
            font-size: 18px;
            &::-webkit-input-placeholder,
            textarea::-webkit-input-placeholder {
              font-size: 18px;
              color: rgba(188, 0, 57, 100);
            }
          }
          .search_btn {
            width: 67px;
            height: 40px;
            text-align: center;
            font-size: 16px;
            color: #bc0039;
            border: 1px solid #bc0039;
            border-radius: 10px;
            padding: 0px;
          }
          .search_query {
            background-color: #ffeded;
          }
        }
        .agent_table{
          /deep/.ant-table {
            color: #101010;
            .ant-table-thead > tr > th{
              color: #101010;
            }
          }
          /deep/.ant-table-placeholder {
            border-bottom: 1px solid #bc0039;
            border-top: 1px solid #bc0039;
          }
          /deep/.ant-pagination {
            .ant-pagination-next, .ant-pagination-prev {
                .ant-pagination-item-link{
                    &:hover{
                        color: #BC0039;
                    }
                }
            }
            .ant-pagination-next:focus .ant-pagination-item-link{
                color: #BC0039;
            }
            .ant-pagination-item{
                a:hover{
                    color: #BC0039;
                }
            }
            .ant-pagination-item-active{
                border-color: #BC0039;
                a{color: #BC0039;}
            }
          }
        }
      }
      .staff_warp{
        margin-top: 10px;
        width: 1030px;
        background: #fff;
        padding: 10px 30px;
        .staff_head{
          font-size: 22px;
          color: #101010;
        }
        .staff_server{
          text-align: center;
          height: 400px;
          .img_warp{
            margin: 40px auto;
            height: 200px;
            width: 200px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          span{
            font-size: 14px;
            color: #101010;
          }
        }
      }
      .schedule_warp{
        width: 460px;
        background: #fff;
        padding: 10px 20px;
        .schedule_title{
          font-size: 22px;
          color: #101010;
        }
        /deep/ .events{
          list-style: none;
          margin: 0px;
          padding: 0px;
          .circle{
            display: inline-block;
            width: 4px;
            height: 4px;
            background: #BC0039;
            border-radius: 50%;
          }
        }
        /deep/ .ant-fullcalendar{
          border: 1px solid rgba(188, 0, 57, 0.28);
          thead{
            border-bottom: 1px solid rgba(248, 218, 218, 100);
            th {
              padding-bottom: 0.521vw;
            }
          }
          .ant-fullcalendar-tbody{
            .ant-fullcalendar-selected-day{
              // background: #BC0039;
              .ant-fullcalendar-value{
                width: 30px;
                height: 30px;
                border-radius: 15px;
                line-height: 30px;
                text-align: center;
                background: #F8DADA;
                color: #101010;
              }
            }
            .ant-fullcalendar-today{
              .ant-fullcalendar-value{
                width: 30px;
                height: 30px;
                border-radius: 15px;
                line-height: 30px;
                text-align: center;
                background: #BC0039 ;
                border: none;
                box-shadow: 0 0 0 0px;
                color: #fff;
              }
            }
            .ant-fullcalendar-cell{
              .ant-fullcalendar-value:active {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                line-height: 30px;
                text-align: center;
                background: #F8DADA;
                color: #101010;
              }
              .ant-fullcalendar-value:hover{

              }
            }
          }
        }
        .add_schedule{
          text-align: center;
          margin-top: 20px;
          .addBtn{
            width: 430px;
            height: 45px;
            background: rgba(188, 0, 57, 100);
            color: rgba(255, 255, 255, 100);
            font-size: 18px;
            border-radius: 22px;
            border: 1px solid rgba(188, 0, 57, 100);
          }
        }
        .render_list{
          margin-top: 35px;
          .list_title{
            text-align: center;
            font-size: 18px;
          }
          .list_warp{
            margin-top: 20px;
            .list_li{
              cursor: pointer;
              .vertical {
                display: inline-block;
                width: 2px;
                height: 20px;
                margin: 0px 20px;
                background: #BC0039;
                vertical-align: text-bottom;
              }
              .li_title{
                display: inline-block;
                width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                vertical-align: bottom;
              }
            }
          }
        }
      }
    }
  }
}
.calendarHeader{
  text-align: center;
  font-size: 18px;
  color: #BC0039;
 .calendarBtn{
   padding: 0px;
   border: none;
   color: #BC0039;
   &::after{
     content: "";
     --antd-wave-shadow-color:#fff;
   }
   &.prevBtn {
     margin-right: 60px;
   }
   &.nextBtn {
     margin-left: 60px;
   }
 }
}
body /deep/.ant-modal-root .ant-modal{
  .ant-modal-body{
    .ant-modal-confirm-btns{
      .ant-btn-primary{
        background: #bc0039;
      }
    }
  }
}
.okBtn{
  color: #fff;
  background: #BC0039;
}
</style>
