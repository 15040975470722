<template>
  <a-modal
      :visible="visible"
      :footer="null"
      :width="1200"
      :mask="false"
      :centered="true"
      :closable="false"
      @cancel="cancelOk"
    >
    <div class="app_warp">
      <div class="app_top">
        <div class="app_title">可添加的应用</div>
        <div class="app_save">
          <a-button @click="saveOk">完成</a-button>
        </div>
      </div>
      <a-spin :spinning="loading">
        <div class="app_list">
          <a-row :gutter="10">
            <a-col span="3" v-for="(app, index) in appList" :key="app.id" @click="choice(app, index)" @dblclick.native="cancelChoice(app, index)">
              <div class="app_li">
                <div class="li_avatar">
                  <img :src="staticDomainURL + app.appimage" alt="">
                </div>
                <div class="li_name">{{app.appname}}</div>
                <div class="li_circle" v-if="app.circle">
                  <a-icon type="check-circle" />
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-spin>
      
    </div>
  </a-modal>
</template>
<script>
import Vue from 'vue'
import { USER_INFO } from "@/store/mutation-types"
import { getAction } from '@/api/manage'
var time = null;

export default {
  data() {
    return {
      loading: false,
      visible: false,
      userInfo: Vue.ls.get(USER_INFO),
      staticDomainURL: window._CONFIG['staticDomainURL'],
      appList: [],
    }
  },
  created() {
    // this.getUserNoApp()
  },
  methods:{
    // 获取用户为拥有的APP
    getUserNoApp(){
      this.loading = true
      getAction('/mesnac/zmdgrzx/zmdMyapps/getUserNoApp', {userId: this.userInfo.id}).then(res => {
        if(res.success) {
          this.loading = false
          this.appList = res.result
        }
      })
    },
    add() {
      this.visible = true
      this.getUserNoApp()
    },
    // 选中
    choice(app, index) {
      const that = this
      clearTimeout(time);
      time = setTimeout(function(){
        app.circle = true
        that.appList.splice(index, 1 , app)
      }, 300);
    },
    // 取消选中
    cancelChoice(app, index) {
      clearTimeout(time)
      app.circle = false
      this.appList.splice(index, 1 , app)
      console.log('this.appList: ', this.appList);

    },
    // 保存 
    saveOk() {
      let ids = ''
      this.appList.forEach(item => {
        if(item.circle) {
          ids += item.id+','
        }
      })
      let formData = {
        appids: ids,
        userid: this.userInfo.id,
        username: this.userInfo.username
      }
       console.log('formData', formData)
      getAction('/mesnac/zmdgrzx/zmdMyapps/saveList', formData).then(res =>{
        if(res.success) {
          this.$message.success(res.message)
          this.$emit('ok')
        } else {
          this.$message.warning(res.message)
        }
      }).finally(() => {
        this.cancelOk()
      })
    },
    cancelOk() {
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>
.app_warp{
  .app_top{
    display: flex;
    justify-content: space-between;
    .app_title{
      font-size: 14px;
      color: #101010;
    }
    .app_save{
      /deep/ .ant-btn{
        width: 80px;
        height: 30px;
        line-height: 28px;
        padding: 0px;
        font-size: 14px;
        color: #BC0039;
        border: 1px solid #BC0039;
        border-radius: 20px;
      }
    }
  }
  .app_list{
    margin-top: 30px;
    .app_li{
      cursor: pointer;
      // width: 150px;
      text-align: center;
      margin-bottom: 10px;
      position: relative;
      .li_avatar{
        margin: 0 auto;
        height: 70px;
        img{
          width: 70px;
          height: 70px;
        }
      }
      .li_name{
        margin-top: 8px;
        font-size: 14px;
        color: #101010;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .li_circle{
        position: absolute;
        top: -10px;
        right: -10px;
        color: green;
      }
    }
  }
}
</style>


